import React from "react";
import Script from "next/script";
function MapSection({gisWellApi,gisSeis2DApi, gisSeis3DApi}) {
   
  return (
    <>
      <a className="anchor-target" id="map-section"></a>
      <div className="container py-5">
        
        <h4 className="news-prime-title" data-aos="zoom-in" data-aos-duration={300}><i className="fa fa-lg fa-light fa-map-location-dot text-warning"></i> Peta Ketersediaan Data Sumur dan Seismic Migas </h4>
        <div className="m-2">&nbsp;</div>
        <div className="container rounded" id="map" ></div>
      </div>

      <Script>
        {`
 
//  basemap 
var osm = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
    maxZoom: 19,
    subdomains: ['mt0']
  });

  // icon point
  var icon = L.icon({
    iconUrl: "js_landing/js_gis/js/images/marker-icon.png",
    iconSize: [15, 15],
    iconAnchor: [13.5, 17.5],
    popupAnchor: [0, -11]
  });

  
  // layer seismic 3d
  var seismic3d = L.esri.dynamicMapLayer({
    url: "${gisSeis3DApi}",

    style: function (feature) {
      return {
        color: "#808080",
        fillColor: "#ffbfbf",
        weight: 2
      };
    }
  });

  // // popup seismic 3d
  // seismic3d.bindPopup(function (layer) {
  //   return L.Util.template(
  //     "<p> Name : <strong>{AC_SRV_NME}</strong></p>",
  //     layer.feature.properties
  //   );
  // });

  // layer seismic 2d
  var seismic2d = L.esri.dynamicMapLayer({
    url: "${gisSeis2DApi}",

  });

  // // popup seismic 2d
  // seismic2d.bindPopup(function (layer) {
  //   return L.Util.template(
  //     "<p>Line Name : <strong>{LINE_NAME}</strong></p>",
  //     layer.feature.properties
  //   );
  // });

  // layer well
  var well = L.esri.dynamicMapLayer({
    url: "${gisWellApi}",
    pointToLayer: function (geojson, latlng) {
      return L.marker(latlng, {
        icon: icon
      });
    }
  });

  // // popup well
  // well.bindPopup(function (layer) {
  //   return L.Util.template(
  //     "<p>Well Name : <strong>{WELL_NAME}</strong> </p>",
  //     layer.feature.properties
  //   );
  // });

  // initial maps
  var map = L.map("map", {
    center: [-2.600029, 118.015776],
    zoom: 5,
    minZoom: 5,
    zoomControl: false,
    attributionControl: false,
    gestureHandling: true,
    layers: [osm, seismic2d, seismic3d, well]
  });

  // initial basemap
  var baseLayers = {
    Basemap: osm,

  };

  // initial layers overlay
  var overlays = {
    "Well": well,
    "Seismic 2D": seismic2d,
    "Seismic 3D": seismic3d,
  };

  // group layers
  L.control.layers(baseLayers, overlays, {
    collapsed: false
  }).addTo(map);

  // widget home
  let zoomHome = L.Control.zoomHome();
  zoomHome.addTo(map);

  // widget search
  var arcgisOnline = L.esri.Geocoding.arcgisOnlineProvider();

  L.esri.Geocoding.geosearch({
    providers: [
      arcgisOnline,
      L.esri.Geocoding.mapServiceProvider({
        label: "Well",
        url: "${gisWellApi}",
        layers: [0],
        searchFields: ["WELL_NAME"]
      }),
      L.esri.Geocoding.mapServiceProvider({
        label: "Seismic 2D",
        url: "${gisSeis2DApi}",
        layers: [0],
        searchFields: ["LINE_NAME"]
      }),
      L.esri.Geocoding.mapServiceProvider({
        label: "Seismic 3D",
        url: "${gisSeis3DApi}",
        layers: [0],
        searchFields: ["AC_SRV_NME"]
      })

    ]
  }).addTo(map);

  // widget legenda
  let legend = L.control({
    position: "bottomleft"
  });

  legend.onAdd = function (map) {
      
    var div = L.DomUtil.create("div", "legend");
    div.style.backgroundColor = "RGBA(245, 238, 237, 0.8)";
    div.style.padding = "0.2rem";
    div.innerHTML += '<h4>Legenda</h4>';
    div.innerHTML += '<div style="display: flex; align-items:center; gap:5px"><div style="background: #0091a1; border: 1px solid black; border-radius:50%; width: 20px; height: 20px; diplay: inline;"></div><span style="font-weight: bold; display: block;">Well <span id="well"></span></span></div>';
    div.innerHTML += '<div style="display: flex; align-items:center; gap:5px"><div style="background: #a80030; border: 1px solid black; width: 20px; height: 5px; diplay: inline;"></div><span style="font-weight: bold; display: block;">Seismic 2D <span id="seismic2d"> </span></div>';
    div.innerHTML += '<div style="display: flex; gap:5px"><div style="background: #ffbfbf; border: 1px solid black; width: 20px; height: 20px; diplay: inline;"></div><p style="font-weight: bold">Seismic 3D <span id="seismic3d"></span></p></div>';

    return div;
    
  };

  legend.addTo(map);
  
  async function wellc() {
    let response = await fetch('https://datamigas.esdm.go.id/arcgis/rest/services/MDR2/well/MapServer/0/query?f=json&where=1=1&returnCountOnly=true');
    let responseText = await response.json();
  
    document.getElementById('well').innerHTML = '('+ responseText.count + ')';
  }

  (async() => {
    await wellc();
  })();

  async function seismic2dc() {
    let response = await fetch('https://datamigas.esdm.go.id/arcgis/rest/services/MDR2/Seismik_2D_LN/MapServer/0/query?f=json&where=1=1&returnCountOnly=true');
    let responseText = await response.json();
  
    document.getElementById('seismic2d').innerHTML = '('+ responseText.count + ')';
  }

  (async() => {
    await seismic2dc();
  })();

  async function seismic3dc() {
    let response = await fetch('https://datamigas.esdm.go.id/arcgis/rest/services/MDR2/seismic_3d/MapServer/0/query?f=json&where=1=1&returnCountOnly=true');
    let responseText = await response.json();
    document.getElementById('seismic3d').innerHTML = '('+ responseText.count + ')';
  }

  (async() => {
    await seismic3dc();
    })();



        `}
      </Script>
    </>
  );
}

export default MapSection;
